<template>
  <FormContainer @submitForm="$emit('submitForm', $event)">
    <template #default>
      <slot />
    </template>
  </FormContainer>
</template>

<script>

import FormContainer from '@/libs/Form/components/FormContainer.vue';

export default {
  components: { FormContainer },
};

</script>

<style lang="scss">

$form-error-color: red !default;

.form-container {
  .form-label {
    margin-top: 25px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: .95rem;
  }

  .form-control::placeholder {
    color: #D0D0D0;
  }

  .form-control:focus {
    border-width: 2px;
  }

  .v-select.dropdown {
    .dropdown-toggle {
      border-radius: 0;
      padding: 4px 0;

      .form-control {
        border: none !important;
      }
    }
  }

  .vdp-datepicker {
    input:focus {
      outline: none !important;
      border-width: 1px;
      border-style: solid;
    }
  }

  .form-feedback-message {
    font-weight: bold;
  }

  .form-error {
    .form-label,
    .form-feedback-message {
      color: $form-error-color !important;
    }

    .form-control,
    .dropdown-toggle {
      color: $form-error-color !important;
      border-color: $form-error-color !important;
    }
  }
}

</style>
