<template>
  <main class="page-password-change d-flex align-items-start justify-content-center">
    <AwlModalCard title="Crea nuova password">
      <ResetPasswordChangeForm />
    </AwlModalCard>
  </main>
</template>

<script>

import AwlModalCard from '@/views/components/Cards/AwlModalCard.vue';
import ResetPasswordChangeForm from '@/views/components/Account/ResetPasswordChangeForm.vue';

export default {
  components: { AwlModalCard, ResetPasswordChangeForm },
};

</script>
