<template>
  <main class="page-login d-flex align-items-start justify-content-center">
    <AwlModalCard title="Accedi">
      <div v-if="socialLoginEnabled" class="pb-3">
        <p>Accedi con i social</p>
        <Alert v-if="errorProvider">Impossibile eseguire il login con {{ errorProvider }}, riprovare.</Alert>
        <button class="btn btn-fb mr-5" @click.prevent="loginWithProvider('facebook')" :disabled="doingLogin"><i class="fab fa-facebook-f"></i> Facebook</button>
        <button class="btn btn-google" @click.prevent="loginWithProvider('google')" :disabled="doingLogin"><i class="fab fa-google"></i> Google</button>
      </div>
      <div v-if="socialLoginEnabled" class="row mt-3 mb-0">
        <div class="col-auto">oppure</div>
        <div class="col"><hr></div>
      </div>
      <LoginForm ref="login-form" :doing-login.sync="doingLogin" @login-started="errorProvider = null" />
    </AwlModalCard>
  </main>
</template>

<script>

import AwlModalCard from '@/views/components/Cards/AwlModalCard.vue';
import LoginForm from '@/views/components/Account/LoginForm.vue';

export default {
  components: {
    AwlModalCard,
    LoginForm,
    Alert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  data: () => ({
    socialLoginEnabled: false,
    doingLogin: false,
    errorProvider: null,
  }),
  methods: {
    loginWithProvider (providerName) {
      if (this.doingLogin) {
        return;
      }

      this.$refs['login-form'].clearFeedback();
      this.errorProvider = null;
      this.doingLogin = true;

      this.$oauth2
        .loginWithProvider(providerName)
        .then(() => {
          this.$router.push({ name: 'home' });
        })
        .catch(err => {
          this.$log.error(err);
          this.errorProvider = providerName;
        })
        .finally(() => {
          this.doingLogin = false;
        })
      ;
    },
  },
  watch: {
    doingLogin (newVal) {
      if (!newVal) {
        return;
      }

      this.errorProvider = null;
    },
  },
};

</script>

<style lang="scss"></style>
