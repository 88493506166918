<template>
  <main class="page-password-reset d-flex align-items-start justify-content-center">
    <AwlModalCard title="Reset Password">
      <PasswordResetForm />
    </AwlModalCard>
  </main>
</template>

<script>

import AwlModalCard from '@/views/components/Cards/AwlModalCard.vue';
import PasswordResetForm from '@/views/components/Account/PasswordResetForm.vue';

export default {
  components: { AwlModalCard, PasswordResetForm },
};

</script>
