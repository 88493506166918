<template>
  <div v-if="success === true">
    <p class="h4">Password modificata con successo</p>
    <p class="h6">Se non vieni reindirizzato al Login entro breve clicca <router-link :to="{ name: 'login' }">qui</router-link></p>
  </div>
  <FormContainer v-else @submitForm="changePassword">
    <Alert v-if="success === false">Si è verificato un errore non previsto, si prega di riprovare</Alert>

    <div class="row">
      <FormInputTextWidget
        class="col-12"
        label="Nuova password"
        v-model="password"
        :errorMsg="errorHash.password"
        type="password"
        @input="removeError('password')"
      />

      <FormInputTextWidget
        class="col-12"
        label="Conferma password"
        v-model="confirmPassword"
        :errorMsg="errorHash.confirmPassword"
        type="password"
        @input="removeError('confirmPassword')"
      />
    </div>

    <div class="row">
      <div class="col-12 mt-4">
        <button class="btn btn-primary" type="submit">Reset</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';

import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';

export default {
  data: () => ({
    password: null,
    confirmPassword: null,
    success: null,
  }),
  components: {
    FormContainer,
    FormInputTextWidget,
    Alert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  mixins: [validateMixin],
  methods: {
    showFinalMessage () {
      this.success = true;
      setTimeout(() => {
        this.$router.replace({ name: 'login' });
      }, 2000);
    },
    notifyError () {
      this.success = false;
    },
    changePassword () {
      if (!this.isValid()) {
        return;
      }

      this.$api.changePasswordWithToken(
        this.$route.params.email,
        this.$route.params.token,
        this.password,
      ).then(this.showFinalMessage, this.notifyError).catch(this.notifyError);
    },
    isValid () {
      if (!this.password) {
        this.addError('Password mancante', 'password');
      } else if (this.password.length < 8 || !this.password.match(/\d|[^\w\s]/)) {
        this.addError('La password deve avere almeno 8 caratteri, compreso un numero o un simbolo.', 'password');
      } else if (!this.confirmPassword) {
        this.addError('Conferma password mancante', 'confirmPassword');
      } else if (this.password !== this.confirmPassword) {
        this.addError('La conferma password deve essere uguale alla password', 'confirmPassword');
      }
      return !this.hasErrors;
    },
  },
};

</script>

<style lang="scss"></style>
