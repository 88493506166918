export default {
  data: () => ({ errors: [] }),
  computed: {
    errorHash () {
      const hash = {};

      this.errors.forEach(e => {
        hash[e.field] = e.msg;
      });

      return hash;
    },
    hasErrors () {
      return this.errors.length > 0;
    },
  },
  methods: {
    addError (msg, field) {
      if (!field) {
        field = '';
      }

      this.errors.push({ field, msg });

      return false;
    },
    removeError (field) {
      this.errors = this.errors.filter(e => e.field !== field);
    },
    clearErrors () {
      this.errors = [];
    },
  },
};
